import axios from 'axios';
import { PAISANOW_API_URL } from '../const';


export const delinQuencyReportAPI=(delinQuencyReportData)=>{
    const data ={
        DelequencyReportToken:delinQuencyReportData
    };
    const url = `${PAISANOW_API_URL}DeleQuency`;
    return axios.post(url,data,{headers: { 'Content-type': 'application/json' } });
}

export const delinquencyExportAPI = (Data) => {
    console.log('service');
    const params = new URLSearchParams();
    params.append("DelinquencyDetailsReportToken",Data)   
    const url= `${PAISANOW_API_URL}Report/DelinQuencyCSV.php`;
    return axios.post(url,params,{ headers:{ 'Content-Type':'application/x-www-form-urlencoded'}} );
}
