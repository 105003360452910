import { docodeResponse } from "../common/common.utils";
import changeOfficerTypes from "./changeOfficer.types";
import generalTypesAction from "../genera.types";

const INITIAL_STATE = {
    officerListArr:[],
    officerListStatus:null,
    officerWiseDataArr:[],
    officerWiseDataStatus:null,
    officerUpdateStatus:null,
    officerUpdateResponse:null,
    pincodeWiseMemberDataStatus:null,
    pincodeWiseMemberDataResponse:null
}

// eslint-disable-next-line default-param-last
const changeOfficerReducer = (state=INITIAL_STATE,action)=>{
    switch(action.type){
        case changeOfficerTypes.FETCH_OFFICER_LIST_SUCCESS:
            return {
                ...state,
                officerListStatus:'Success',
                officerListArr:docodeResponse(action.payload,generalTypesAction.CHANGEOFFICER)
            };
        case changeOfficerTypes.FETCH_OFFICER_LIST_FAILURE:
            return {
                ...state,
                officerListStatus:'Failure',
                officerListArr:[]
            };
        case changeOfficerTypes.FETCH_OFFICER_LIST_CHANGE:
            return {
                ...state,
                officerListStatus:null
            }

        case changeOfficerTypes.OFFICER_CHANGE_DATA_FETCH_SUCCESS:
            return{
                ...state,
                officerWiseDataStatus:'Success',
                officerWiseDataArr:docodeResponse(action.payload,generalTypesAction.CHANGEOFFICER)
            }
        
        case changeOfficerTypes.OFFICER_CHANGE_DATA_FETCH_FAILURE:
            return{
                ...state,
                officerWiseDataStatus:'Failure',
                officerWiseDataArr:[]
            }

        case changeOfficerTypes.OFFICER_CHANGE_DATA_FETCH_CHANGE:
            return {
                ...state,
                officerWiseDataStatus:null
            }

        case changeOfficerTypes.OFFICER_UPDATE_SUCCESS:
            return {
                ...state,
                officerUpdateStatus:'Success',
                officerUpdateResponse:action.payload
            }

        case changeOfficerTypes.OFFICER_UPDATE_FAILURE:
            return {
                ...state,
                officerUpdateStatus:'Failure',
                officerUpdateResponse:action.payload
            }

        case changeOfficerTypes.OFFICER_UPDATE_CHANGE:
            return {
                ...state,
                officerUpdateStatus:null,
            }

        case changeOfficerTypes.PINCODE_WISE_MEMBER_DATA_FETCH_SUCCESS:
            return {
                ...state,
                pincodeWiseMemberDataStatus:'Success',
                pincodeWiseMemberDataResponse:docodeResponse(action.payload,generalTypesAction.CHANGEOFFICER)
            };

        case changeOfficerTypes.PINCODE_WISE_MEMBER_DATA_FETCH_FAILURE:
            return {
                ...state,
                pincodeWiseMemberDataStatus:'Failure',
                pincodeWiseMemberDataResponse:null
            };

        case changeOfficerTypes.PINCODE_WISE_MEMBER_DATA_FETCH_CHANGE:
            return {
                ...state,
                pincodeWiseMemberDataStatus:null,
            };

        default:
            return state;
    }

}

export default changeOfficerReducer;