import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
    delinQuencyReportChanged,
    delinQuencyReportSuccess,
    delinQuencyReportFailure,
    delinQuencyReportExportSuccess,
    delinQuencyReportExportFailure,
} from './delinQuencyReport.action';
import delinQuencyReportTypes from "./delinQuencyReport.types";
import generalTypesAction from "../genera.types";
import { encodeJson } from "../enode-decode";

import {delinQuencyReportAPI,delinquencyExportAPI}  from './delinQuencyReport.services';

export function* delinQuencyReportFetch ({payload}){
    
    try {
        const jsonData={
            fromDays:payload.fromDay,
            toDays: payload.toDays,
            UserID: payload.UserID,
            CompanyID: payload.companyID,
            TillDate: payload.tillDate,
            SearchValue: payload.searchValue,
        };
        const delinQuencyReportData = encodeJson(jsonData,generalTypesAction.DELINQUENCY);
        const responseData = yield call(delinQuencyReportAPI,delinQuencyReportData);
        console.log(responseData);
        if( responseData.data.statusCode === '100'){
            yield put(delinQuencyReportFailure(responseData.data));
        }else {
            yield put(delinQuencyReportSuccess(responseData.data));
        }
    } catch (error) {
        yield put(delinQuencyReportFailure(error));
    }
}
export function* delinQuencyReportChange(){
    yield put(delinQuencyReportChanged());
}

export function* delinQuencyReportFetchStart(){
    yield takeLatest(delinQuencyReportTypes.DELINQUENCY_REPORT_FETCH_START,delinQuencyReportFetch);
}

export function* delinQuencyReportReset(){
    yield takeLatest(delinQuencyReportTypes.DELINQUENCY_REPORT_FETCH_RESET,delinQuencyReportChange);
}

export function* DelinquencyExportWorker({ payload })  {
    
    try {
        const jsonData={
            fromDays:payload.fromDay,
            toDays: payload.toDays,
            UserID: payload.UserID,
            CompanyID: payload.companyID,
            TillDate: payload.tillDate,
            SearchValue: payload.searchValue,
        };
        // const dataArr = [jsonData];
    
        const delinQuencyReportData = encodeJson(jsonData,generalTypesAction.DELINQUENCY);
        const responseData = yield call(delinquencyExportAPI,delinQuencyReportData);
      if (responseData.statusCode === '100') {
        yield put(delinQuencyReportExportFailure(responseData.data));
      } else {
        yield put(delinQuencyReportExportSuccess(responseData.data));
      }
    } catch (error) {
      yield put(delinQuencyReportExportFailure(error));
    }
  }
  export function* delinquencyStart() {
    yield takeLatest(delinQuencyReportTypes.DELINQUENCY_EXPORT_START, DelinquencyExportWorker);
  }

export function* delinQuencySaga(){
    yield all(
        [
            call(delinQuencyReportFetchStart),
            call(delinQuencyReportReset),
            call(delinquencyStart)
        ]
    );
}