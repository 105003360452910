const delinQuencyReportTypes = {
    DELINQUENCY_REPORT_FETCH_START:'DELINQUENCY_REPORT_FETCH_START',
    DELINQUENCY_REPORT_FETCH_SUCCESS:'DELINQUENCY_REPORT_FETCH_SUCCESS',
    DELINQUENCY_REPORT_FETCH_FAILURE:'DELINQUENCY_REPORT_FETCH_FAILURE',
    DELINQUENCY_REPORT_FETCH_CHANGED:'DELINQUENCY_REPORT_FETCH_CHANGED',
    DELINQUENCY_REPORT_FETCH_RESET:'DELINQUENCY_REPORT_FETCH_START',

    DELINQUENCY_EXPORT_START :'DELINQUENCY_EXPORT_START',
    DELINQUENCY_EXPORT_SUCCESS :'DELINQUENCY_EXPORT_SUCCESS',
    DELINQUENCY_EXPORT_FAILURE : 'DELINQUENCY_EXPORT_FAILURE',
}

export default delinQuencyReportTypes;