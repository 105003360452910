const demandCollectionTypes={
    DEMAND_COLLECTION_FECTH_LIST_START:'DEMAND_COLLECTION_FECTH_LIST_START',
    DEMAND_COLLECTION_FECTH_LIST_SUCCESS:'DEMAND_COLLECTION_FECTH_LIST_SUCCESS',
    DEMAND_COLLECTION_FECTH_LIST_FAILURE:'DEMAND_COLLECTION_FECTH_LIST_FAILURE',
    DEMAND_COLLECTION_FECTH_LIST_RESET:'DEMAND_COLLECTION_FECTH_LIST_RESET',
    DEMAND_COLLECTION_FECTH_LIST_CHANGED:'DEMAND_COLLECTION_FECTH_LIST_CHANGED',

    DEMAND_COLLECTION_UPDATE_START:'DEMAND_COLLECTION_UPDATE_START',
    DEMAND_COLLECTION_UPDATE_SUCCESS:'DEMAND_COLLECTION_UPDATE_SUCCESS',
    DEMAND_COLLECTION_UPDATE_FAILURE:'DEMAND_COLLECTION_UPDATE_FAILURE',
    DEMAND_COLLECTION_UPDATE_RESET:'DEMAND_COLLECTION_UPDATE_RESET',
    DEMAND_COLLECTION_UPDATE_CHANGED:'DEMAND_COLLECTION_UPDATE_CHANGED'
}

export default demandCollectionTypes;