const changeOfficerTypes={
    
    FETCH_OFFICER_LIST_START:'FETCH_OFFICER_LIST_START',
    FETCH_OFFICER_LIST_SUCCESS:'FETCH_OFFICER_LIST_SUCCESS',
    FETCH_OFFICER_LIST_FAILURE:'FETCH_OFFICER_LIST_FAILURE',
    FETCH_OFFICER_LIST_CHANGE:'FETCH_OFFICER_LIST_CHANGE',
    FETCH_OFFICER_LIST_RESET:'FETCH_OFFICER_LIST_RESET',

    OFFICER_CHANGE_DATA_FETCH_START:'OFFICER_CHANGE_DATA_FETCH_START',
    OFFICER_CHANGE_DATA_FETCH_SUCCESS:'OFFICER_CHANGE_DATA_FETCH_SUCCESS',
    OFFICER_CHANGE_DATA_FETCH_FAILURE:'OFFICER_CHANGE_DATA_FETCH_FAILURE',
    OFFICER_CHANGE_DATA_FETCH_CHANGE:'OFFICER_CHANGE_DATA_FETCH_CHANGE',
    OFFICER_CHANGE_DATA_FETCH_RESET:'OFFICER_CHANGE_DATA_FETCH_RESET',

    PINCODE_WISE_MEMBER_DATA_FETCH_START:'PINCODE_WISE_MEMBER_DATA_FETCH_START',
    PINCODE_WISE_MEMBER_DATA_FETCH_SUCCESS:'PINCODE_WISE_MEMBER_DATA_FETCH_SUCCESS',
    PINCODE_WISE_MEMBER_DATA_FETCH_FAILURE:'PINCODE_WISE_MEMBER_DATA_FETCH_FAILURE',
    PINCODE_WISE_MEMBER_DATA_FETCH_CHANGE:'PINCODE_WISE_MEMBER_DATA_FETCH_CHANGE',
    PINCODE_WISE_MEMBER_DATA_FETCH_RESET:'PINCODE_WISE_MEMBER_DATA_FETCH_RESET',


    OFFICER_UPDATE_START:'OFFICER_UPDATE_START',
    OFFICER_UPDATE_SUCCESS:'OFFICER_UPDATE_SUCCESS',
    OFFICER_UPDATE_FAILURE:'OFFICER_UPDATE_FAILURE',
    OFFICER_UPDATE_CHANGE:'OFFICER_UPDATE_CHANGE',
    OFFICER_UPDATE_RESET:'OFFICER_UPDATE_RESET'
}

export default changeOfficerTypes;