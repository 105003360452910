import  delinQuencyReportTypes from './delinQuencyReport.types';

export const delinQuencyReportStart = (data)=>({
    type:delinQuencyReportTypes.DELINQUENCY_REPORT_FETCH_START,
    payload:data,
});

export const delinQuencyReportSuccess = (data)=>({
    type:delinQuencyReportTypes.DELINQUENCY_REPORT_FETCH_SUCCESS,
    payload:data,
});

export const delinQuencyReportFailure = (error)=>({
    type:delinQuencyReportTypes.DELINQUENCY_REPORT_FETCH_FAILURE,
    payload:error,
});

export const delinQuencyReportChanged = ()=>({
    type:delinQuencyReportTypes.DELINQUENCY_REPORT_FETCH_CHANGED,
});

export const delinQuencyReportReset = ()=>({
    type:delinQuencyReportTypes.DELINQUENCY_REPORT_FETCH_RESET,
});



export const delinQuencyReportExportStart = (payload) => ({
    type: delinQuencyReportTypes.DELINQUENCY_EXPORT_START,
    payload,
});
    
export const delinQuencyReportExportSuccess = (payload) => ({
    type: delinQuencyReportTypes.DELINQUENCY_EXPORT_SUCCESS,
    payload,
});
    
export const delinQuencyReportExportFailure = (payload) => ({
    type: delinQuencyReportTypes.DELINQUENCY_EXPORT_FAILURE,
    payload,
});
