import demandCollectionTypes from "./demandCollection.types";
import generalTypesAction from "../genera.types";
import { docodeResponse } from "../common/common.utils";


const INITIAL_DATA={
    demandCollectionFetchStatus:null,
    demandCollectionFetchResponse:null,
    demandCollectionFetchResponseTotal:null,
    demandCollectionUpdateStatus:null,
    demandCollectionUpdateResponse:null,
}

// eslint-disable-next-line default-param-last
const demandCollectionReducer = (state = INITIAL_DATA, action) => {
    switch(action.type){
        case demandCollectionTypes.DEMAND_COLLECTION_FECTH_LIST_SUCCESS:
            return{
                ...state,
                demandCollectionFetchStatus:"Success",
                demandCollectionFetchResponseTotal:action.payload,
                demandCollectionFetchResponse:docodeResponse(action.payload, generalTypesAction.DEMANDCOLLECTION)
            }
        
        case demandCollectionTypes.DEMAND_COLLECTION_FECTH_LIST_FAILURE:
            return{
                ...state,
                demandCollectionFetchStatus:"Failure",
                demandCollectionFetchResponse:null
            }
        
        case demandCollectionTypes.DEMAND_COLLECTION_FECTH_LIST_CHANGED:
            return{
                ...state,
                demandCollectionFetchStatus:null,
            }

        case demandCollectionTypes.DEMAND_COLLECTION_UPDATE_SUCCESS:
            return{
                ...state,
                demandCollectionUpdateStatus:'Success',
                demandCollectionUpdateResponse:action.payload,
            }

        case demandCollectionTypes.DEMAND_COLLECTION_UPDATE_FAILURE:
            return{
                ...state,
                demandCollectionUpdateStatus:'Failure',
                demandCollectionUpdateResonse:action.payload
            }

        case demandCollectionTypes.DEMAND_COLLECTION_UPDATE_CHANGED:
            return{
                ...state,
                demandCollectionUpdateStatus:null,
            }
        
        default:
            return state
    }
}

export default demandCollectionReducer;