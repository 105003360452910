import changeOfficerTypes from "./changeOfficer.types";

// FETCH OFFICER LIST 
export const officerListStart= (changeOfficer)=>({
    type:changeOfficerTypes.FETCH_OFFICER_LIST_START,
    payload:changeOfficer,
});

export const officerListSuccess= (success)=>({
    type:changeOfficerTypes.FETCH_OFFICER_LIST_SUCCESS,
    payload:success,
});

export const  officerListFailure= (error)=>({
    type:changeOfficerTypes.FETCH_OFFICER_LIST_FAILURE,
    payload:error,
});

export const  officerListChanged= ()=>({
    type:changeOfficerTypes.FETCH_OFFICER_LIST_CHANGE,
});

export const  officerListReset= ()=>({
    type:changeOfficerTypes.FETCH_OFFICER_LIST_RESET,
});


// FETCH OFFICE CHANGE DATA
export const  changeOfficerListStart= (changeOfficerData)=>({
    type:changeOfficerTypes.OFFICER_CHANGE_DATA_FETCH_START,
    payload:changeOfficerData
});

export const  changeOfficerListSuccess= (success)=>({
    type:changeOfficerTypes.OFFICER_CHANGE_DATA_FETCH_SUCCESS,
    payload:success
});

export const  changeOfficerListFailure= (error)=>({
    type:changeOfficerTypes.OFFICER_CHANGE_DATA_FETCH_FAILURE,
    payload:error
});

export const  changeOfficerListChange= ()=>({
    type:changeOfficerTypes.OFFICER_CHANGE_DATA_FETCH_CHANGE
});

export const  changeOfficerListReset= ()=>({
    type:changeOfficerTypes.OFFICER_CHANGE_DATA_FETCH_RESET,
});

// PINCODE WISE DATA 
export const  pincodeWiseMemberListStart= (changeOfficerData)=>({
    type:changeOfficerTypes.PINCODE_WISE_MEMBER_DATA_FETCH_START,
    payload:changeOfficerData
});

export const  pincodeWiseMemberListSuccess= (success)=>({
    type:changeOfficerTypes.PINCODE_WISE_MEMBER_DATA_FETCH_SUCCESS,
    payload:success
});

export const  pincodeWiseMemberListFailure= (error)=>({
    type:changeOfficerTypes.PINCODE_WISE_MEMBER_DATA_FETCH_FAILURE,
    payload:error
});

export const  pincodeWiseMemberListChanged= ()=>({
    type:changeOfficerTypes.PINCODE_WISE_MEMBER_DATA_FETCH_CHANGE
});

export const  pincodeWiseMemberListResetStart= ()=>({
    type:changeOfficerTypes.PINCODE_WISE_MEMBER_DATA_FETCH_RESET,
});


// OFFICER UPDATE

export const  officerUpdateStart= (updateData)=>({
    type:changeOfficerTypes.OFFICER_UPDATE_START,
    payload:updateData
});

export const  officerUpdateSuccess= (success)=>({
    type:changeOfficerTypes.OFFICER_UPDATE_SUCCESS,
    payload:success
});

export const  officerUpdateFailure= (error)=>({
    type:changeOfficerTypes.OFFICER_UPDATE_FAILURE,
    payload:error
});

export const  officerUpdateChanged= ()=>({
    type:changeOfficerTypes.OFFICER_UPDATE_CHANGE
});

export const  officerUpdateReset= ()=>({
    type:changeOfficerTypes.OFFICER_UPDATE_RESET,
});

