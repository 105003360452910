import axios from "axios";
import { PAISANOW_API_URL } from "../const";


export const demandCollectionFetchAPI=(Data)=>{
    const data ={
        demandCollectionToken:Data
    };
    const url = `${PAISANOW_API_URL}demandCollectionFetch`;
    return axios.post(url,data,{ headers: { 'Content-type':'application/json' } });
}

export const demandCollectionUpdateAPI=(Data)=>{
    const data={
        demandCollectionUpdateToken:Data
    };
    const url =`${PAISANOW_API_URL}demandCollectionUpdate`;
    return axios.post(url,data, { headers: { 'Content-type':'application/json'} })
}
 