import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

// officer List api
export const officerListFetchAPI=(payload)=>{
    const data ={
        fetchOfficerDetailsToken:payload
    }
    const url  = `${PAISANOW_API_URL}fetchBranchWiseStaffDetails`;
    return axios.post(url,data,{ headers:{ 'Content-Type':'application/json' }});
}
// officer wise details api
export const officerWiseDataFetchAPI=(payload)=>{
    const data ={
        changeOfficerDetailsToken:payload
    }
    const url  = `${PAISANOW_API_URL}fetchOfficerWiseDetails`;
    return axios.post(url,data,{ headers:{ 'Content-Type':'application/json' }});
}

// officer update api
export const officerUpdateAPI=(payload)=>{
    const data ={
        updateOfficerToken:payload
    }
    const url  = `${PAISANOW_API_URL}officerUpdate`;
    return axios.post(url,data,{ headers:{ 'Content-Type':'application/json' }});
}

// pincoded wise data fetch
export const pincodeWiseMemberFetchAPI=(payload)=>{
    const data ={
        changeOfficerMemberDetailsToken:payload
    }
    const url  = `${PAISANOW_API_URL}changeOfficerPincodeWiseMemberFetch`;
    return axios.post(url,data,{ headers:{ 'Content-Type':'application/json' }});
}