/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import delinQuencyReportTypes from "./delinQuencyReport.types";



const INITIAL_STATE={
    delinQuencyReportStatus:null,
    delinQuencyReportResponse: null
};

// eslint-disable-next-line default-param-last
const delinQuencyReportReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case delinQuencyReportTypes.DELINQUENCY_REPORT_FETCH_SUCCESS:
            return{
                ...state,
                delinQuencyReportStatus:'Success',
                delinQuencyReportResponse:action.payload 
            };
        case delinQuencyReportTypes.DELINQUENCY_REPORT_FETCH_FAILURE:
            return{
                ...state,
                delinQuencyReportStatus:'Failure',
                delinQuencyReportResponse:action.payload,
            };
        case delinQuencyReportTypes.DELINQUENCY_REPORT_FETCH_CHANGED:
            return{
                ...state,
                delinQuencyReportStatus:null,
            };

        default:
            return state;
    }
};

export default delinQuencyReportReducer;