import {
    call,takeLatest,put,
    all
} from 'redux-saga/effects';
import demandCollectionTypes from './demandCollection.types';
import {
    demandCollectionFetchSuccess,
    demandCollectionFetchFailure,
    demandCollectionFetchChange,
    demandCollectionUpdateSuccess,
    demandCollectionUpdateFailure,
    demandColllectionUpdateChanged
} from './demanCollection.action'
import { encodeJson } from '../enode-decode';
import  generalTypesAction from '../genera.types';
import {demandCollectionFetchAPI,demandCollectionUpdateAPI} from './demandCollection.service'

export function* demandCollectionFetchStart({payload}){
 try {
    const jsonData ={     
        CompanyID: payload.companyID,
        UserID: payload.userID,
        // UserID: '3889 ',
        Date: payload.todayDate,
        BranchID:payload.branchID,
        CollectionType: "Demand",
        showAllMember: "AllMember",
    }
    const demandCollectionData = encodeJson(jsonData,generalTypesAction.DEMANDCOLLECTION);
    const responseData =  yield call(demandCollectionFetchAPI,demandCollectionData);
    if(responseData.data.statusCode === '100'){
        yield put(demandCollectionFetchFailure(responseData.data))
    }else {
        yield put(demandCollectionFetchSuccess(responseData.data));
    }
 } catch (error) {
    yield put(demandCollectionFetchSuccess(error));
 }
}
export function* demandCollectionFetchChanged(){
    yield put(demandCollectionFetchChange())
}

export function* demandCollectionFetchStartWatcher(){
    yield takeLatest(demandCollectionTypes.DEMAND_COLLECTION_FECTH_LIST_START,demandCollectionFetchStart)
}
export function* demandCollectionFetchResetWatcher(){
    yield takeLatest(demandCollectionTypes.DEMAND_COLLECTION_FECTH_LIST_RESET,demandCollectionFetchChanged);
}


export function* demandCollectionUpdateStartSaga({payload}){
    try {
        const jsonData={
            CompanyID: payload.companyID,
            UserID: payload.userID,
            // UserID: '3889 ',
            BranchID:payload.branchID,
            CollectionType: "Demand",
            CollectionList:payload.collectionList,
            FiveHundred:payload.fiveHundred,
            TwoHundred:payload.twoHundred,
            Hundred:payload.hundred,
            Fifty:payload.fifty,
            Twenty:payload.twenty,
            Ten:payload.ten,
            Five:payload.five,
            Two:payload.two,
            One:payload.one,
            TotalAmount:payload.totalAmount
        };

        const demandCollectionData = encodeJson(jsonData,generalTypesAction.DEMANDCOLLECTION);
        const response = yield call(demandCollectionUpdateAPI,demandCollectionData);

        if(response.data.statusCode === '100'){
            yield put(demandCollectionUpdateFailure(response.data));
        }else {
            yield put(demandCollectionUpdateSuccess(response.data));
        }
    } catch (error) {
        demandCollectionUpdateFailure(error);
    }
}
export function* demandCollectionUpdateChangeSaga(){
    yield put(demandColllectionUpdateChanged())
}

export function* demandCollectionUpdateStartWatcher(){
    yield takeLatest(demandCollectionTypes.DEMAND_COLLECTION_UPDATE_START,demandCollectionUpdateStartSaga)
}

export function* demandCollectionUpdateResetWatcher(){
    yield takeLatest(demandCollectionTypes.DEMAND_COLLECTION_UPDATE_RESET,demandCollectionUpdateChangeSaga)
}
export function* demandCollectionSaga(){
    yield all([
        call(demandCollectionFetchStartWatcher),
        call(demandCollectionFetchResetWatcher),
        call(demandCollectionUpdateStartWatcher),
        call(demandCollectionUpdateResetWatcher)
    ])
}