/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
// import JWT from 'expo-jwt';
import profileActionTypes from './profile.types';
import generalTypesAction from '../genera.types';
import { getProfile, updateProfile } from './profile.services';
import {
	getProfileDetailSuccess,
	getProfileDetailFailure,
	getProfileDetailChanged,
	getProfileUpdateSuccess,
	getProfileUpdateFailure,
	getProfileUpdateChanged,
} from './profile.actions';
import { encodeJson } from '../enode-decode';

export function* profileDetails({ payload: { companyID } }) {
	try {
		const jsonData = {
			CompanyID: companyID,
		};
		const ProfileData = encodeJson(jsonData, generalTypesAction.COMPANY);
		const responseData = yield call(getProfile, ProfileData);
		if (responseData.data.statusCode === '100') {
			yield put(getProfileDetailFailure(responseData.data));
		} else {
			yield put(getProfileDetailSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getProfileDetailFailure(error));
	}
}

export function* profileUpdateDetails({ payload }) {
	try {
	
		const jsonData = {
			UserID: payload.userID,
			CompanyID: payload.companyID,
			CardName: payload.companyCardName,
			TagLine: payload.companyTagLine,
			About: payload.aboutCompany,
			Customers: payload.companyCustomer,
			Limits: payload.companyLimit,
			HappyCustomer: payload.companyHappyCustomer,
			QuickVerification: payload.companyQuickVerification,
			CardColor: payload.companyCardColor,
			MemberVerificationSwitchValue:payload.memberVerificationSwitchValue,
			FamilyVerificationSwitchValue:payload.familyVerificationSwitchValue,
			NomineeVerificationSwitchValue:payload.nomineeVerificationSwitchValue,
			CoApplicantVerificationSwitchValue:payload.coApplicantVerificationSwitchValue,
			GuarantorVerificationSwitchValue:payload.guarantorVerificationSwitchValue,
			ResidentVerificationSwitchValue:payload.residentVerificationSwitchValue
		};
		const ProfileData = encodeJson(jsonData, generalTypesAction.COMPANY);
		const responseData = yield call(updateProfile, ProfileData);
		if (responseData.data.statusCode === '100') {
			yield put(getProfileUpdateFailure(responseData.data));
		} else {
			yield put(getProfileUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getProfileUpdateFailure(error));
	}
}

export function* profileDetailsReset() {
	yield put(getProfileDetailChanged());
}

export function* profileUpdateDetailsReset() {
	yield put(getProfileUpdateChanged());
}

export function* profileDetailsStart() {
	yield takeLatest(profileActionTypes.PROFILE_REPSONSE_START, profileDetails);
}

export function* profileDetailsResponseReset() {
	yield takeLatest(profileActionTypes.PROFILE_REPSONSE_RESET, profileDetailsReset);
}

export function* profileUpdateStart() {
	yield takeLatest(profileActionTypes.PROFILE_UPDATE_REPSONSE_START, profileUpdateDetails);
}

export function* profileUpdateResponseReset() {
	yield takeLatest(profileActionTypes.PROFILE_UPDATE_REPSONSE_RESET, profileUpdateDetailsReset);
}

export function* profileSaga() {
	yield all([
		call(profileDetailsStart),
		call(profileDetailsResponseReset),
		call(profileUpdateStart),
		call(profileUpdateResponseReset),
	]);
}
