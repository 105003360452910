import demandCollectionTypes from "./demandCollection.types";

// FETCH DEMNAD COLLECTION
export const demandCollectionFetchStart=(data)=>({
    type:demandCollectionTypes.DEMAND_COLLECTION_FECTH_LIST_START,
    payload:data
});
export const demandCollectionFetchSuccess=(data)=>({
    type:demandCollectionTypes.DEMAND_COLLECTION_FECTH_LIST_SUCCESS,
    payload:data
});
export const demandCollectionFetchFailure=(data)=>({
    type:demandCollectionTypes.DEMAND_COLLECTION_FECTH_LIST_FAILURE,
    payload:data
});
export const demandCollectionFetchChange=(data)=>({
    type:demandCollectionTypes.DEMAND_COLLECTION_FECTH_LIST_CHANGED,
    payload:data
});
export const demandCollectionFetchReset=(data)=>({
    type:demandCollectionTypes.DEMAND_COLLECTION_FECTH_LIST_RESET,
    payload:data
});


// DEMNAD COLLECTION UPDATE
export const demandCollectionUpdateStart=(data)=>({
    type:demandCollectionTypes.DEMAND_COLLECTION_UPDATE_START,
    payload:data
});


export const demandCollectionUpdateSuccess=(success)=>({
    type:demandCollectionTypes.DEMAND_COLLECTION_UPDATE_SUCCESS,
    payload:success
});

export const demandCollectionUpdateFailure=(error)=>({
    type:demandCollectionTypes.DEMAND_COLLECTION_UPDATE_FAILURE,
    payload:error
});


export const demandColllectionUpdateReset=()=>({
    type:demandCollectionTypes.DEMAND_COLLECTION_UPDATE_RESET
});

export const demandColllectionUpdateChanged=()=>({
    type:demandCollectionTypes.DEMAND_COLLECTION_UPDATE_CHANGED
});