/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { call, all, put, takeLatest } from 'redux-saga/effects';
import changeOfficerTypes from './changeOfficer.types';
import generalTypesAction from '../genera.types';
import { encodeJson } from '../enode-decode';
import {
    officerListSuccess,
    officerListFailure,
    officerListChanged,
    changeOfficerListSuccess,
    changeOfficerListFailure,
    changeOfficerListChange,
    officerUpdateChanged,
    officerUpdateFailure,
    officerUpdateSuccess,
    pincodeWiseMemberListSuccess,
    pincodeWiseMemberListFailure,
    pincodeWiseMemberListChanged
} from './changeOfficer.action';
import { officerListFetchAPI ,officerWiseDataFetchAPI,officerUpdateAPI,pincodeWiseMemberFetchAPI} from './changeOfficer.service';


// officer list fetch call
export function* officerListFetch({payload}){
    try {
        const jsonData={
            BranchID:payload.branchID,
            CompanyID:payload.companyID
        }
        const encodeData = encodeJson(jsonData,generalTypesAction.CHANGEOFFICER);
        const responseData = yield call(officerListFetchAPI,encodeData);

        if(responseData.data.statusCode ==='100'){
            yield put(officerListFailure(responseData.data));
        } else{
            yield put(officerListSuccess(responseData.data))
        }
    } catch (error) {
        yield put(officerListFailure(error))
    }
}

export function* officerListResetChange(){
    yield put(officerListChanged())
}

export function* officerListStart(){
    yield takeLatest(changeOfficerTypes.FETCH_OFFICER_LIST_START,officerListFetch);
}

export function* officerListResetStart(){
    yield takeLatest(changeOfficerTypes.FETCH_OFFICER_LIST_RESET,officerListResetChange)
}


// officer wise details fetch 
export function* officerWiseDetails({payload}){
    try {
        
        const jsonData = {
            UserID:payload.userID,
            BranchID: payload.branchID,
            CompanyID: payload.companyID
        }
        const encodeData =  encodeJson(jsonData,generalTypesAction.CHANGEOFFICER);
        const responseData  =  yield call(officerWiseDataFetchAPI,encodeData);
        if(responseData.data.statusCode === '100'){
            yield put(changeOfficerListFailure(responseData.data))
        } else {
            yield put(changeOfficerListSuccess(responseData.data))
        }

    } catch (error) {
        yield  put(changeOfficerListFailure(error));   
    }
}

export function* officerWiseChanged(){
    yield put(changeOfficerListChange())
}

export function* officerWiseDetailsStart(){
    yield takeLatest(changeOfficerTypes.OFFICER_CHANGE_DATA_FETCH_START,officerWiseDetails)
}

export function* officerWiseDetailsResetStart(){
    yield takeLatest(changeOfficerTypes.OFFICER_CHANGE_DATA_FETCH_RESET,officerWiseChanged)
}


// officer update 

export function* updateOfficer({payload}){

    try {
        const jsonData ={
            OfficerID:payload.officerID,
            CompanyID:payload.companyID,
            officerUpdateList:payload.OfficerList
        }
        const encodeData  = encodeJson(jsonData,generalTypesAction.CHANGEOFFICER);
        const responseData =  yield call(officerUpdateAPI,encodeData);

        if(responseData.data.statusCode ==='100'){
           yield put(officerUpdateFailure(responseData.data));
        } else{
           yield put(officerUpdateSuccess(responseData.data));
        }
       
    } catch (error) {
        yield put(officerUpdateFailure(error));
    }
}

export function* updateOfficerChanged(){
    yield put(officerUpdateChanged())
}

export function* updateOfficerStart(){
    yield takeLatest(changeOfficerTypes.OFFICER_UPDATE_START,updateOfficer)
}

export function* updateOfficerResetStart(){
    yield takeLatest(changeOfficerTypes.OFFICER_UPDATE_RESET,updateOfficerChanged)
}


// pincode wise member fetch
export function* pincodeWiseMemberListFetch({payload}){
    try {
        const jsonData ={
            CompanyID:payload.companyID,
            UserID:payload.userID,
            BranchID: payload.branchID,
            Pincode: payload.pincode
        }
        const encodeData  = encodeJson(jsonData,generalTypesAction.CHANGEOFFICER);
        const responseData =  yield call(pincodeWiseMemberFetchAPI,encodeData);

        if(responseData.data.statusCode ==='100'){
            yield put(pincodeWiseMemberListFailure(responseData.data));
        } else{
            yield put(pincodeWiseMemberListSuccess(responseData.data));
        }
    } catch(error){
        yield put(pincodeWiseMemberListFailure(error));
    }
}

export function* pincodeMemberListResetChange(){
    yield put(pincodeWiseMemberListChanged())
}

export function* pincodeWiseFetchStart(){
    yield takeLatest(changeOfficerTypes.PINCODE_WISE_MEMBER_DATA_FETCH_START,pincodeWiseMemberListFetch)
}

export function* pincodeWiseMemberListReset(){
    yield takeLatest(changeOfficerTypes.PINCODE_WISE_MEMBER_DATA_FETCH_RESET,pincodeMemberListResetChange)
}


// main saga for change officer
export function* changeOfficerMainSaga(){
    yield all([
        call(officerListStart),
        call(officerListResetStart),
        call(officerWiseDetailsStart),
        call(officerWiseDetailsResetStart),
        call(updateOfficerStart),
        call(updateOfficerResetStart),
        call(pincodeWiseFetchStart),
        call(pincodeWiseMemberListReset),
    ])
}